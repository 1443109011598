// noprotect
import React from "react";
import { MapContainer, TileLayer, Marker } from "react-leaflet";
import L from "leaflet";
import "leaflet/dist/leaflet.css";
import icon from "leaflet/dist/images/marker-icon.png";
import iconShadow from "leaflet/dist/images/marker-shadow.png";

let DefaultIcon = L.icon({
  iconUrl: icon,
  shadowUrl: iconShadow,
  iconAnchor: [12, 41], // point of the icon which will correspond to marker's location
  // popupAnchor: [25, -41] // point from which the popup should open relative to the iconAnchor
});

L.Marker.prototype.options.icon = DefaultIcon;

class MapWrapperFixed extends React.Component {
  render() {
    const { zoom, marker, handleClickMap } = this.props;
    return (
      <div className="mt-10">
        <MapContainer 
        center={marker} 
        zoom={zoom} 
        className="mapHeight"
        whenReady={(map) => {
          map.target.on("click", handleClickMap);
        }}
        >
          <TileLayer
            attribution="&copy;INSEE, IGN"
            url="https://wxs.ign.fr/pratique/geoportail/wmts?service=WMTS&request=GetTile&version=1.0.0&tilematrixset=PM&tilematrix={z}&tilecol={x}&tilerow={y}&layer=ORTHOIMAGERY.ORTHOPHOTOS&format=image/jpeg&style=normal"
            maxZoom={19}
            minZoom={19}
          />

          <Marker position={marker}></Marker>
        </MapContainer>
      </div>
    );
  }
}

export default MapWrapperFixed;
