import React from 'react';
import NavBar from "../NavBar";

class LayoutA extends React.Component {
    render() {
        return <div className="LayoutA">
            <div className="container">
                <NavBar></NavBar>
                {this.props.children}
            </div>
        </div>
    }
}

export default LayoutA;