import React from "react";
import TagManager from "react-gtm-module";
import { Link } from "react-router-dom";
class SuccessMsg extends React.Component {

    
  componentDidMount() {
    const tagManagerArgs = {
      dataLayer: {
        event:  "form-etape-lead",
        userCodePostal: this.props.codePostal,
        typeOffre: "Solaire", 
        userLogement : "Maison"
      },
      dataLayerName: 'PageDataLayer',
  }
  console.log(this.props.codePostal)
    TagManager.dataLayer(tagManagerArgs)

    // console.log('success GTM loaded')
  }


  render() {
    return (
      <div>
        <h2 className="lead-h2 mt-50 is-semibold">
          Félicitations, votre demande a bien été prise en compte.{" "}
        </h2>
        <h3 className="text-blue is-semibold">
          Un conseiller Hellio va vous recontacter rapidement afin de mieux
          connaître votre projet et vous proposer la solution la plus adaptée.
        </h3>

        <h3 className="mt-30">
          <strong>
            Vous allez recevoir très prochainement un email de confirmation.{" "}
          </strong>
          <br />
          Si ce n'est pas le cas, pensez à vérifier vos spams.
        </h3>

        {this.props.handleBackHome && 
                <button class="nextBtn mt-50" onClick={this.props.handleBackHome}>Refaire une simulation</button>
        }

        {!this.props.handleBackHome && 
                <Link to="/"><button class="nextBtn mt-50">Refaire une simulation</button></Link>
        }

      </div>
    );
  }
}

export default SuccessMsg;
