import React, { Component } from 'react';
import Page from "./Page";
import Contact from "./Contact";
import ContactAppartement from "./ContactAppartement";
import ContactFinal from "./ContactFinal";
import Step1home from './lead/Step1home'
import Step2mapParcelle from './lead/Step2mapParcelle';
import Step3expo from './lead/Step3expo';
import Step4logement from './lead/Step4logement';
import Step5toiture from './lead/Step5toiture';
import Step6tarif from './lead/Step6tarif';
import Step7facture from './lead/Step7facture';
import Step8delais from './lead/Step8delais';
import Offres from "./Offres";
import "./LeadMain.scss";
class LeadMain extends Component {
    constructor(props) {
        super(props);
        this.state = {
            step: 1,
            center: [46, 0],
            marker: [46, 0],
            mapData: false,
            zoom: 19,
            address: '',
            // Form content
            street: '',
            city: '',
            codePostal: '',
            roofSize: 60,
            roofHeight: '',
            roofWidth: '',
            factureMontant: 150,
            tarif: '',
            tilt: 50,
            tiltDegree: 30,
            exposition: 'Sud',
            delais: '',
            openPredictions: false,
            install: '',
            // baseInfo
            lastname: '',
            firstname: '',
            salutation: 'Madame',
            email: '',
            phone: '',
            // Api call 1
            api1result: '',
            isLoaded1: false,
            error1: '',
            // Api call 2 
            api2result: '',
            isLoaded2: false,
            error2: '',
            cadastre: [],
            roofStep: 0,
        }
        this.handleAddressChange = this.handleAddressChange.bind(this);
        this.placeSelected = this.placeSelected.bind(this);
        this.handleClickMap = this.handleClickMap.bind(this);
        this.handleRoofHeight = this.handleRoofHeight.bind(this);
        this.handleRoofWidth = this.handleRoofWidth.bind(this);
        this.handleFactureMontant = this.handleFactureMontant.bind(this);
        this.handleFactureMontantInput = this.handleFactureMontantInput.bind(this);
        this.handleTarif = this.handleTarif.bind(this);
        this.handleExposition = this.handleExposition.bind(this);
        this.handleAppartement = this.handleAppartement.bind(this);
        this.handleDelais = this.handleDelais.bind(this);
        this.handleNextStep = this.handleNextStep.bind(this);
        this.handleLastStep = this.handleLastStep.bind(this);
        this.handleBackHome = this.handleBackHome.bind(this);
        this.calculeRoof = this.calculeRoof.bind(this);
        this.apiCall1 = this.apiCall1.bind(this);
        this.apiCall2 = this.apiCall2.bind(this);
        this.handleSalutation = this.handleSalutation.bind(this);
        this.handleFirstname = this.handleFirstname.bind(this);
        this.handleLastname = this.handleLastname.bind(this);
        this.handleEmail = this.handleEmail.bind(this);
        this.handlePhone = this.handlePhone.bind(this);
        this.handleInstall = this.handleInstall.bind(this);
    }


    handleAddressChange = (e) => {
        this.setState({ address: e.target.value, onChangeAddress: true });
    }

    placeSelected = (place) => {
        this.setState({
            address: place.formatted_address,
            street: `${place.address_components[0].long_name} ${place.address_components[1].long_name}`,
            city: place.address_components[2].long_name,
            codePostal: place.address_components[6].long_name,
            center: [
                place.geometry.location.lat(),
                place.geometry.location.lng(),
            ] || [46, 20],
            marker: [
                place.geometry.location.lat(),
                place.geometry.location.lng(),
            ] || [46, 20],         
            mapData: true,
            validate: true,
            openPredictions: false,
            roofWidth: '',
            roofHeight: '',
        });
        this.getCadastre();
        // this.getUtmParams();
    }

    handleKeyUp = (e) => {
        const pac = document.getElementsByClassName('pac-item')
        if(pac.length > 0) {
            this.setState({
                openPredictions: true
            })
        } else {
            this.setState({
                openPredictions: false
            })
        }
    }

    handleClickMap = (e) => {
        const { lat, lng } = e.latlng;
        this.setState({ marker: [lat, lng]})
    }
    

    handleRoofHeight = (e) => {
        if(e.target.value > 0) {
        this.setState({ roofHeight: Number(e.target.value)});
        }
    }

    handleRoofWidth = (e) => {
        if(e.target.value > 0) {
            this.setState({ roofWidth: Number(e.target.value)});
        }
    }

    handleFactureMontant = (e) => {
            this.setState({ factureMontant: Number(e)});
    }


    handleFactureMontantInput = (e) => {
        if(e.target.value >= 1 && e.target.value <= 1000) {
            this.setState({ factureMontant: Number(e.target.value)});
        }
    }


    handleFactureMontantPlus = () => {
        if(this.state.factureMontant < 1000) {
            this.setState({ factureMontant: this.state.factureMontant + 1});
        }
    }

    handleFactureMontantMinus = () => {
        if(this.state.factureMontant > 5) {
            this.setState({ factureMontant: this.state.factureMontant - 1});
        }
    }

    handleSalutation = (e) => {
        this.setState({ salutation: e.target.value});
    }

    handleFirstname = (e) => {
        this.setState({ firstname: e.target.value});
    }
    handleLastname = (e) => {
        this.setState({ lastname: e.target.value});
    }
    handlePhone = (e) => {
        this.setState({ phone: e.target.value});
    }
    handleEmail= (e) => {
        this.setState({ email: e.target.value});
    }

    handleTarif(e) {
        this.setState({ tarif: e.target.value, step: this.state.step + 1, });
    }

    handleInstall(e) {
        this.setState({ install: e.target.value, step: this.state.step + 1, });
    }


    handleTilt = (e) => {
        const markList = {0: '0', 25: '15', 50: '30', 75: '40', 100: '45' }
        this.setState({ tilt: e, tiltDegree: markList[e]});
    }

    handleExposition = (e) => {
        this.setState({ exposition: e.target.value});
    }

    handleAppartement = (e) => {
        this.setState({ step: 800});
    }


    handleDelais = (e) => {
        this.setState({ delais: e.target.value});
        this.handleNextStep();
    }

    handleSubmit = () => {
        alert(`
        address: ${this.state.address},
        logement: ${this.state.logement},
        factureMontant: ${this.state.factureMontant},
        tarif: ${this.state.tarif},
        tilt: ${this.state.tilt},
        exposition: ${this.state.exposition},
        delais: ${this.state.delais},
        `)
    }

    handleBackHome = () => {
            this.setState({
                isLoaded1: false,
                isLoaded2: false,
                api1result:'',
                api2result: '',
                step: 0,
                roofStep: 0,
            });
    }

    handleLastStep = () => {
        if(this.state.step > 0 && this.state.step <= 8 ) {
            this.setState({
                step: this.state.step - 1,
            });
        } 
    }

    handleNextStep = () => {
        if(this.state.step === 5) { // Toiture
            this.apiCall1()
        }


        if(this.state.step === 7) {
            this.apiCall2()
        }

        this.setState({
            step: this.state.step + 1,
        });     
    }

    getCadastre = () => {
        const urlCreatLead = `${process.env.REACT_APP_URL_API}/getParcelles?lat=${this.state.marker[0]}&long=${this.state.marker[1]}`;
        fetch(urlCreatLead, {
          method: "GET",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `${process.env.REACT_APP_TOKEN_API}`
          },
        })
          .then((result) => result.json())   
          // .then((result) => )
          .then(
            (result) => {
              this.setState({
                cadastre: result['data'],
              });
            //   console.log(result['data'])
            },
            // Note: it's important to handle errors here
            // instead of a catch() block so that we don't swallow
            // exceptions from actual bugs in components.
            (error) => {
            }
          );
    }

    roofLastStep= () => {
        if(this.state.roofStep > 0) {
            this.setState({ roofStep: this.state.roofStep -1 });
        }
    }
    roofNextStep = () => {
        if(this.roofStep === 1) {
            this.setState({ roofSize: 0, roofStep: 2 });
        }
        
        if(this.state.roofStep < 2) {
            this.setState({ roofStep: this.state.roofStep +1 });
        }
    }

    roofSizeCount = () => {
        this.setState({ 
            roofSize: this.state.roofHeight*this.state.roofWidth, 
        });

        if(this.state.roofSize !== 0) {
            this.setState({ 
                step: this.state.step + 1,
            })
        }
    }
    calculeRoof = () => {
        const urlCreatLead = `${process.env.REACT_APP_URL_API}/calculeRoof?lat=${this.state.marker[0]}&long=${this.state.marker[1]}`;
        this.setState({
           roofSizeLoading: true,
        });
        fetch(urlCreatLead, {
          method: "GET",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `${process.env.REACT_APP_TOKEN_API}`
          },
        })
          .then((result) => result.json())   
          // .then((result) => )
          .then(
            (result) => {
              if(!result['data'] || this.state.roofSize === 0) {
                this.setState({
                    roofSize: 60,
                    roofSizeLoading: false,
                    roofStep: 2,
                 });
              } else {
                this.setState({
                    roofSize: result['data'],
                    roofSizeLoading: false,
                    roofStep: 1,
                  });
              }

            },
            // Note: it's important to handle errors here
            // instead of a catch() block so that we don't swallow
            // exceptions from actual bugs in components.
            (error) => {
                this.setState({
                    roofSize: 60,
                    roofSizeLoading: false,
                    roofStep: 2,
                 });
            }
          );
    }
    apiCall1 = () => {
        const urlCreatLead = `${process.env.REACT_APP_URL_API}/simulation/Api`;
        const bodyData = {
            "lat": this.state.center[0],
            "lon": this.state.center[1],
            "alpha":this.state.tiltDegree,
            "azimut":this.state.exposition,
        };
    
        fetch(urlCreatLead, {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `${process.env.REACT_APP_TOKEN_API}`
          },
          body: JSON.stringify(bodyData)
        })
          .then((result) => result.json())   
          // .then((result) => )
          .then(
            (result) => {
              this.setState({
                isLoaded1: true,
                api1result: result,
              });
            },
            // Note: it's important to handle errors here
            // instead of a catch() block so that we don't swallow
            // exceptions from actual bugs in components.
            (error) => {
              this.setState({
                isLoaded1: true,
                error1: error,
              });
            }
          );
    }

    apiCall2 = () => {
        const urlCreatLead = `${process.env.REACT_APP_URL_API}/simulation/calculation`;
        const bodyData = 
        {
            "req1": this.state.api1result["req1"],
            "req2": this.state.api1result["req2"],
            "s_t": String(this.state.roofSize),
            "base": String(this.state.tarif),
            "p_m": String(this.state.factureMontant),
            "alpha": String(this.state.tiltDegree),
        };
    
        
        fetch(urlCreatLead, {
          method: "POST",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `${process.env.REACT_APP_TOKEN_API}`
          },
          body: JSON.stringify(bodyData)
        })
          .then((result) => result.json())   
          .then(
            (result) => {
              this.setState({
                isLoaded2: true,
                api2result: result,
              });
            },
            // Note: it's important to handle errors here
            // instead of a catch() block so that we don't swallow
            // exceptions from actual bugs in components.
            (error) => {
              this.setState({
                isLoaded2: true,
                error2: error,
              });
            }
          );
    }


    
    renderLead(step) {
        switch (step) {
            default: 
                return <Page.LayoutA>
                    <Step1home 
                    address={this.state.address} 
                    handleAddressChange={this.handleAddressChange} 
                    placeSelected={this.placeSelected} 
                    handleNextStep={this.handleNextStep} 
                    mapData={this.state.mapData} 
                    handleKeyUp={this.handleKeyUp} 
                    openPredictions={this.state.openPredictions} />
                </Page.LayoutA>

            case 1:
                return <Page.LayoutA>
                    <Step1home 
                    address={this.state.address} 
                    handleAddressChange={this.handleAddressChange} 
                    placeSelected={this.placeSelected} 
                    handleNextStep={this.handleNextStep} 
                    mapData={this.state.mapData} 
                    handleKeyUp={this.handleKeyUp} 
                    openPredictions={this.state.openPredictions} />
                </Page.LayoutA>
            case 2:
                return <Step2mapParcelle 
                calculeRoof={this.calculeRoof}
                roofLastStep={this.roofLastStep}
                roofNextStep={this.roofNextStep}
                roofSizeLoading={this.state.roofSizeLoading}
                roofSizeCount={this.roofSizeCount}
                roofSize={this.state.roofSize}
                roofStep={this.state.roofStep}
                address={this.state.address} 
                roofHeight={this.state.roofHeight} 
                roofWidth={this.state.roofWidth} 
                handleRoofHeight={this.handleRoofHeight} 
                handleRoofWidth={this.handleRoofWidth} 
                center={this.state.center} 
                cadastre={this.state.cadastre}
                step={this.state.step} 
                handleClickMap={this.handleClickMap}
                marker={this.state.marker}
                mapData={this.state.mapData} 
                handleAddressChange={this.handleAddressChange}
                placeSelected={this.placeSelected} 
                handleLastStep={this.handleLastStep} 
                handleNextStep={this.handleNextStep} 
                zoom={this.state.zoom} 
                handleKeyUp={this.handleKeyUp} 
                openPredictions={this.openPredictions} 
                codePostal={this.state.codePostal}
                />
            case 3:
                return <Step3expo  
                address={this.state.address} 
                center={this.state.center} 
                step={this.state.step} 
                mapData={this.state.mapData} 
                zoom={this.state.zoom} 
                exposition={this.state.exposition} 
                handleExposition={this.handleExposition} 
                handleNextStep={this.handleNextStep} 
                marker={this.state.marker}
                handleClickMap={this.handleClickMap}
                handleLastStep={this.handleLastStep}
                codePostal={this.state.codePostal}
                />

            case 4:
                return <Page.LayoutC step={this.state.step}>
                    <Step4logement handleAppartement={this.handleAppartement} 
                    handleNextStep={this.handleNextStep} 
                    handleLastStep={this.handleLastStep}
                    api1result={this.state.api1result}
                    error1={this.state.error1}
                    isLoaded1={this.state.isLoaded1}
                    codePostal={this.state.codePostal}
                    />
                    </Page.LayoutC>
            case 5:
                return <Page.LayoutC step={this.state.step}>
                    <Step5toiture 
                    tilt={this.state.tilt} 
                    tiltDegree={this.state.tiltDegree} 
                    handleTilt={this.handleTilt} 
                    handleNextStep={this.handleNextStep} 
                    handleLastStep={this.handleLastStep}  
                    validate={this.state.validate}
                    codePostal={this.state.codePostal}
                    />
                    </Page.LayoutC>
            case 6:
                return <Page.LayoutC step={this.state.step}>
                    <Step6tarif 
                    tarif={this.state.tarif} 
                    handleTarif={this.handleTarif} 
                    handleNextStep={this.handleNextStep} 
                    handleLastStep={this.handleLastStep}  
                    validate={this.state.validate}
                    codePostal={this.state.codePostal}
                    />
                    </Page.LayoutC>
            case 7:
                return <Page.LayoutC step={this.state.step}>
                    <Step7facture 
                    factureMontant={this.state.factureMontant} 
                    handleFactureMontant={this.handleFactureMontant} 
                    handleFactureMontantInput={this.handleFactureMontantInput}
                    handleNextStep={this.handleNextStep}
                    handleLastStep={this.handleLastStep} 
                    validate={this.state.validate} 
                    handleFactureMontantPlus={this.handleFactureMontantPlus}
                    handleFactureMontantMinus={this.handleFactureMontantMinus}
                    isLoaded1={this.state.isLoaded1}
                    codePostal={this.state.codePostal}
                    />
                </Page.LayoutC>
            case 8:
                return <Page.LayoutC step={this.state.step}>
                    <Step8delais 
                    delais={this.state.delais} 
                    handleDelais={this.handleDelais} 
                    handleSubmit={this.handleSubmit} 
                    validate={this.state.validate} 
                    handleNextStep={this.handleNextStep}  
                    handleLastStep={this.handleLastStep}  
                    codePostal={this.state.codePostal}
                    />
                    </Page.LayoutC>
            
            case 9:
                return   <Offres 
                    handleNextStep={this.handleNextStep}
                    handleBackHome={this.handleBackHome}
                    api2result={this.state.api2result}
                    error2={this.state.error2}
                    isLoaded2={this.state.isLoaded2}
                    handleInstall={this.handleInstall}
                    />              

            case 10: 
            return <Page.LayoutC step={this.state.step}>
                <ContactFinal  
                delais={this.state.delais} 
                codePostal={this.state.codePostal} 
                city={this.state.city} 
                street={this.state.street} 
                install={this.state.install}
                api2result={this.state.api2result}
                tarif={this.state.tarif}
                factureMontant={this.state.factureMontant}
                paraList={this.props.paraList}
                handleBackHome={this.handleBackHome}
                roofSize={this.state.roofSize}
                tiltDegree={this.state.tiltDegree}
                exposition={this.state.exposition}
                />
                </Page.LayoutC>

            case 800: 
            return <Page.LayoutC step={this.state.step}>
                <ContactAppartement 
                city={this.state.city} 
                street={this.state.street}
                codePostal={this.state.codePostal}
                paraList={this.props.paraList}
                handleBackHome={this.handleBackHome}
                />
                </Page.LayoutC>


            case 999: 
                return <Page.LayoutC step={this.state.step}>
                    <Contact paraList={this.props.paraList} handleBackHome={this.handleNextStep}/>
                    </Page.LayoutC>
            }
    }


    render() {
        // const {step} = this.state;
        //console.log(roofSize)
       const { install, roofSize, api1result, api2result, error2, step, center, factureMontant, street, city, codePostal, address, mapData, tarif, tiltDegree, exposition, delais } = this.state;
        const formData = {
            paraList : this.props.paraList,
            roofSize: roofSize,
            api1result: api1result,
            api2result: api2result,
            error2: error2,
            install: install,
            step: step,
            lat: center[0],
            lng: center[1],
            street: street,
            city: city,
            codePostal: codePostal,
            address: address,
            exposition: exposition,
            toiture: tiltDegree,
            tarif: tarif,
            factureMontant: factureMontant,
            delais: delais,
            mapData: mapData,
        }
        console.log(formData)
        return (
            <div>
                {this.renderLead(step)}
            </div>
        );
    }
}

export default LeadMain;