import React, { Component } from "react";
import Slider from "rc-slider";
import TagManager from 'react-gtm-module'

const marks = {
  0: "0°",
  25: "15°",
  50: "30°",
  75: "40°",
  100: "45°",
};

class Step5toiture extends Component {
  constructor(props) {
    super(props);
    this.state = {
      error: null,
      isLoaded: false,
      result: [],
    };
  }

  componentDidMount() {
    const tagManagerArgs = {
      dataLayer: {
        event: "form-etape-inclinaison",
        userCodePostal: this.props.codePostal,
        typeOffre: "Solaire", 
        userLogement : "Maison",
      },
      dataLayerName: 'PageDataLayer',
    }
  
    TagManager.dataLayer(tagManagerArgs)
  }

  render() {
    
    const {
      tilt,
      tiltDegree,
      handleTilt,
      handleNextStep,
      handleLastStep,
    } = this.props;

    return (
      <div>
        <h2 className="lead-h2">
          Quel est le degré d'inclinaison <br className="is-hidden-mobile" />
          <span>de votre toiture ? </span>
        </h2>
        <p className="lead-p mv-20">
          Cette information nous permet de déterminer précisément <br className="is-hidden-mobile" />
          le rendement de vos panneaux solaires.
        </p>

        <div className="toiture-container">
          <div className="toiture-img-container">
            <img src={`../../images/tilt/tilt-${tiltDegree}.svg`} alt="tilt" />
          </div>
          <div className="toiture-slider-container">
            <Slider
              min={0}
              marks={marks}
              step={null}
              value={tilt}
              onChange={handleTilt}
            />
          </div>
        </div>

        {/* <h2 className="lead-h2">Quel est le degré d'inclinaison <br/><span>de votre toiture? </span></h2>
        <p className="lead-p mv-20">Cette information nous permet de déterminer précisément <br/>le rendement de vos panneaux solaires.</p>

        <div className="slider-container mt-50">
          <img src={`../../images/tilt/tilt-${tiltDegree}.svg`} width="350" alt="tilt" />
          <div className="slider">
            <input type="range" min="0" max="4" value={tilt} step="0" list="steplist" className="basic slider-colors"
              onChange={handleTilt} demo="slider-colors"
            />

            <datalist id="steplist">
              <option label="0°">0</option>
              <option label="15°">15</option>
              <option label="30°">30</option>
              <option label="40°">40</option>
              <option label="45°">45</option>
            </datalist>
          </div>
        </div> */}
        <div className="whiteBoxContainer">
          <div>
            <p>Pente de toit </p>
            <h2>{tiltDegree}°</h2>
          </div>
          <div>
            <button onClick={handleNextStep}>
              Suivant{" "}
              <img src="../../images/next-white.svg" alt="suivant" />
            </button>
          </div>
        </div>
        <div className="mt-50">
          <button className="btn-navigation mr-30" onClick={handleLastStep}>
            <img src="../../images/previous-round-orange.svg" alt="previous" />
            <span>Précédent</span>
          </button>
        </div>
      </div>
    );
  }
}
export default Step5toiture;
