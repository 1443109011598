import React, { Component } from 'react';
import ModalVideo from 'react-modal-video'
import '../../../node_modules/react-modal-video/scss/modal-video.scss';
import AddressBar from "../AddressBar";

class Step1home extends Component {

    constructor() {
        super()
        this.state = {
            isOpen: false
        }
        this.openModal = this.openModal.bind(this)
    }


    openModal() {
        this.setState({ isOpen: true })
    }

    render() {
        const { handleAddressChange, placeSelected, handleNextStep, mapData, handleKeyUp, openPredictions,address } = this.props
        return (
            <div className="row">

                <div className="col is-half">
                    <div style={{position: 'relative'}}>
                    <div id="background1"></div>
                    </div>
                    {/* <div id="background2"></div> */}
                    <p className="hero-p mb-5">Gratuit et sans engagement</p>
                    <h1 className="hero-h1">Demandez votre <span>étude solaire</span><br />
                et calculez <span>le montant de <br /> vos économies !</span>
                    </h1>
                    {/*openPredictions ? 'Open' : 'close' */}
                    <h2 className="h2 mv-20">Quelle est l’adresse du logement à équiper ? </h2>

                    <AddressBar 
                    openPredictions={openPredictions} 
                    handleAddressChange={handleAddressChange} 
                    placeSelected={placeSelected} 
                    mapData={mapData} 
                    handleKeyUp={handleKeyUp} 
                    handleNextStep={handleNextStep} 
                    width="370" 
                    address={address}
                    btnText="Je découvre mes économies"/>
                    
                    <div className="is-hidden-mobile">
                    <h3 className="h3 mt-30"><img src="../images/time.svg" width="36" className="v-align" style={{marginRight: '13px', marginTop: '3px'}} alt="icon-time"/>Découvrez en <span>2 minutes !</span></h3>
                    <ul className="text-s" style={{marginLeft: '84px'}}>
                        <li className="mv-10">La production de votre future installation solaire</li>
                        <li className="mv-10">Les économies réalisées sur votre facture</li>
                        <li className="mv-10">Les aides auxquelles vous avez droit</li>
                    </ul>
                    </div>
                </div>
                <div className="col is-half">
                    <img src="../images/hero-mobile.png" className="hero-mobile only-show-mobile" alt="hero"/>
                    <div className="is-hidden-mobile">
                        <React.Fragment>
                            <ModalVideo channel='youtube' autoplay isOpen={this.state.isOpen} videoId='0Kga52H_r6g' onClose={() => this.setState({ isOpen: false })} />
                            <img src="../images/hero-home.png" className="hero-home" onClick={this.openModal} alt="img" style={{width: '83%'}}/>
                        </React.Fragment>


                        <div className="mt-10 ml-30 d-flex">
                            <h1 className="text-xl is-bold" style={{ margin: 0 }}>4,8</h1>
                            <div className="ml-10">
                                <div className="d-flex">
                                    <img src="../images/star.svg" width="20" alt="star"/>
                                    <img src="../images/star.svg" width="20" alt="star"/>
                                    <img src="../images/star.svg" width="20" alt="star"/>
                                    <img src="../images/star.svg" width="20" alt="star"/>
                                    <img src="../images/star-half.svg" width="20" alt="star"/>
                                </div>
                                <p className="text-s mv-10">Avis clients</p>
                            </div>
                        </div>

                    </div>

                </div>
            </div>
        );
    }
}
export default Step1home;