import React, { Component } from "react";
import Slider, { SliderTooltip } from "rc-slider";
import TagManager from "react-gtm-module";

const { createSliderWithTooltip } = Slider;
const Range = createSliderWithTooltip(Slider.Range);
const { Handle } = Slider;

class Step7facture extends Component {
  constructor(props) {
    super(props);
    this.state = {
      errorShow: false,
    };
  }

  componentDidMount() {
    const tagManagerArgs = {
      dataLayer: {
        event: "form-etape-electricite",
        userCodePostal: this.props.codePostal,
        typeOffre: "Solaire",
        userLogement: "Maison",
      },
      dataLayerName: 'PageDataLayer',
    };

    TagManager.dataLayer(tagManagerArgs)
  }

  validateAlert = () => {
    this.setState({ errorShow: true });
  };

  render() {
    const {
      factureMontant,
      handleFactureMontant,
      handleFactureMontantInput,
      handleFactureMontantPlus,
      handleFactureMontantMinus,
      handleNextStep,
      handleLastStep,
      isLoaded1,
    } = this.props;



    return (
      <div className="slider-parent">
        <h2 className="lead-h2">
          Quel est le montant de votre facture <br className="is-hidden-mobile" />
          <span>d’électricité mensuelle ? </span>
        </h2>

        <p className="lead-p mv-20">
          Cette information nous permet de déterminer précisément le rendement
          de vos panneaux solaires.
        </p>

        <div className="facture-montant-slider">
          <img
            src="../../images/icon-minus.png"
            alt="minus"
            onClick={handleFactureMontantMinus}
          />
          <SliderTooltip
            prefixCls="rc-slider-tooltip"
            overlay={`${factureMontant} €`}
            visible={true}
            placement="top"
          >
            <Slider
              min={1}
              max={1000}
              step={1}
              value={factureMontant}
              onChange={handleFactureMontant}
            />
          </SliderTooltip>
          <img
            src="../../images/icon-plus.png"
            className="ml-20"
            alt="plus"
            onClick={handleFactureMontantPlus}
          />
        </div>

        <div className="whiteBoxContainer">
          <div>
            <p>Estimation mensuelle</p>
            <h2>
              <input type="number" 
              min={1}
              max={1000} 
              value={factureMontant} 
              onChange={handleFactureMontantInput} className="input-h2"/>€</h2>
          </div>
          <div>
            <div>
              {!isLoaded1 && <button>Loading...</button>}

              {isLoaded1 && (
                <button onClick={handleNextStep}>
                  Suivant{" "}
                  <img src="../../images/next-white.svg" alt="suivant" />
                </button>
              )}
            </div>
          </div>
        </div>



        <div className="mt-30">
          <button className="btn-navigation mr-30" onClick={handleLastStep}>
            <img src="../../images/previous-round-orange.svg" alt="previous" />
            <span>Précédent</span>
          </button>

          {/* {factureMontant &&
            <button onClick={handleNextStep} className="btn-navigation"><span>Suivant</span> <img src="../../images/next-round-orange.svg" alt="next" /></button>
          }
          {!factureMontant &&
            <button onClick={this.validateAlert} className="btn-navigation"><span>Suivant</span> <img src="../../images/next-round-orange.svg" alt="next" /></button>
          } */}

          {this.state.errorShow && (
            <p className="errorMsg">champs obligatoire *</p>
          )}
        </div>
      </div>
    );
  }
}
export default Step7facture;
