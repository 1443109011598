import React from "react";
import LeadMain from "./components/LeadMain";
//import Test from "./components/Test";
//import Offres from "./components/Offres";
import Contact from "./components/Contact";
import Page from "./components/Page";
import "./styles/main.scss";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import TagManager from "react-gtm-module";

const tagManagerArgs = {
  gtmId: "GTM-P39RH8J",
  dataLayerName: "PageDataLayer",
};

TagManager.initialize(tagManagerArgs);

class App extends React.Component {
  arr2obj = (arr) => {
    let obj = {};
    arr.forEach((v) => {
      let key = v[0];
      let value = v[1];
      obj[key] = value;
    });
    return obj;
  };

  render() {
    let paraList = window.location.search.substring(1).split("&");
    paraList = paraList.map((item) => item.split("="));
    paraList = this.arr2obj(paraList);
    return (
      <div className="App">
        <Router>
          <div>
            <Switch>
              <Route exact path="/">
                <LeadMain paraList={paraList} />
              </Route>
              {/* <Route path="/nos-offres">
                <Page.LayoutA>
                  <NosOffres />
                </Page.LayoutA>
              </Route> */}
              {/* <Route path="/test">
                <Page.LayoutA>
                  <Test />
                </Page.LayoutA>
              </Route> */}
              <Route path="/contact">
                <Page.LayoutB>
                  <Contact paraList={paraList} />
                </Page.LayoutB>
              </Route>
            </Switch>
          </div>
        </Router>
      </div>
    );
  }
}

export default App;
