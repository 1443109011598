import React, { Component } from "react";
// import MapWrapper from "../MapWrapper";
import StepBar from "../StepBar";
import MapWrapperFixed from "../MapWrapper2";
import Logo from "../Logo";
import TagManager from 'react-gtm-module'
class Step3expo extends Component {
  constructor(props) {
    super(props);
    this.state = {
      errorShow: false,
    };
  }

  componentDidMount() {
    const tagManagerArgs = {
      dataLayer: {
        event: "form-etape-exposition",
        userCodePostal: this.props.codePostal,
        typeOffre: "Solaire", 
      },
      dataLayerName: 'PageDataLayer',
    }
    TagManager.dataLayer(tagManagerArgs)
  }


  validateAlert = () => {
    this.setState({ errorShow: true });
  };

  render() {
    const {
      handleExposition,
      handleNextStep,
      handleLastStep,
      handleClickMap,
      step,
      exposition,
      center,
      zoom,
      marker,
    } = this.props;


    let expositions = [
      [
        { short: "N", long: "Nord" },
        { short: "S", long: "Sud" },
      ],
      [
        { short: "NE", long: "Nord-Est" },
        { short: "SO", long: "Sud-Ouest" },
      ],
      [
        { short: "E", long: "Est" },
        { short: "O", long: "Ouest" },
      ],
      [
        { short: "SE", long: "Sud-Est" },
        { short: "NO", long: "Nord-Ouest" },
      ],
    ];

    const items = expositions.map((item) => (
      <div
        className={
          exposition === item[0].long || exposition === item[1].long
            ? "expo-hover selected-expo"
            : "expo-hover"
        }
        key={item.short}
      >
        <div>
          <input
            type="radio"
            id={item[0].short}
            name="exposition"
            value={item[0].long}
            onChange={handleExposition}
            key={item[0].long}
          />
          {exposition === item[0].long && (
            <label htmlFor={item[0].short} key={item[0].short}>
              <img
                src={`../../images/expositions/${item[0].long}_select.svg`}
                alt={item[0].short}
              />
              <span>
                <br />
                {item[0].long}
              </span>
            </label>
          )}
          {exposition !== item[0].long && (
            <label htmlFor={item[0].short} key={item[0].short}>
              <img
                src={`../../images/expositions/${item[0].long}.svg`}
                alt={item[0].short}
              />
              <span>
                <br />
                {item[0].long}
              </span>
            </label>
          )}
        </div>
        <div>
          <input
            type="radio"
            id={item[1].short}
            name="exposition"
            value={item[1].long}
            onChange={handleExposition}
            key={item[1].long}
          />
          {exposition === item[1].long && (
            <label htmlFor={item[1].short} key={item[1].short}>
              <img
                src={`../../images/expositions/${item[1].long}_select.svg`}
                alt={item[1].short}
              />
              <span>
                <br />
                {item[1].long}
              </span>
            </label>
          )}
          {exposition !== item[1].long && (
            <label htmlFor={item[1].short} key={item[1].short}>
              <img
                src={`../../images/expositions/${item[1].long}.svg`}
                alt={item[1].short}
              />
              <span>
                <br />
                {item[1].long}
              </span>
            </label>
          )}
        </div>
      </div>
    ));

    return (
      <div className="outer-container">
        <div className="row">
          <div className="col is-half leftCol">
            <div className="logo-container">
              <Logo grayLogo={true} />
            </div>

            <h2 className="lead-h2">
              Quelle est l’exposition de <span>votre toiture ? </span>
            </h2>
            <p className="lead-p mv-20">
              Cette réponse nous permet d’estimer la durée d'ensoleillement{" "}
              <br className="is-hidden-mobile" />
              disponible sur votre habitation.
            </p>
            {exposition && (
              <div
                className="w-100 is-hidden-mobile"
                style={{ position: "relative" }}
              >
                <img
                  src={`../../images/expositions/${exposition}_compass.svg`}
                  width="200"
                  className="compass"
                  alt="compass"
                />
              </div>
            )}

            <div className="exposition-list">{items}</div>

            {this.state.errorShow && !exposition && (
              <p className="errorMsg">Veuillez choisir une option</p>
            )}

            <div className="mt-50">
              <button className="btn-navigation mr-30" onClick={handleLastStep}>
                <img src="../../images/previous-round-orange.svg" alt="next" />
                <span>Précédent</span>
              </button>

              {exposition && (
                <button className="btn-navigation" onClick={handleNextStep}>
                  <span>Suivant</span>{" "}
                  <img src="../../images/next-round-orange.svg" alt="next" />
                </button>
              )}

              {!exposition && (
                <button className="btn-navigation" onClick={this.validateAlert}>
                  <span>Suivant</span>{" "}
                  <img src="../../images/next-round-orange.svg" alt="next" />
                </button>
              )}
            </div>
          </div>

          <div className="col is-half is-hidden-mobile">
            <StepBar step={step} />
            <MapWrapperFixed
              center={center}
              zoom={zoom}
              marker={marker}
              handleClickMap={handleClickMap}
            ></MapWrapperFixed>
          </div>
        </div>
      </div>
    );
  }
}

export default Step3expo;
