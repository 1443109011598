import { React, useEffect } from "react";
import { Formik, Form, Field, useField } from "formik";
import * as Yup from "yup";
import TagManager from "react-gtm-module";
const phoneRegExp = /^0\d{9}$/;
const DisplayingErrorMessagesSchema = Yup.object().shape({
  salutation: Yup.string().required("Ce champ est requis"),
  firstname: Yup.string().required("Ce champ est requis"),
  lastname: Yup.string().required("Ce champ est requis"),
  mobilephone: Yup.string("Veuillez entrer un numéro valide")
    .matches(phoneRegExp, "Veuillez entrer un numéro valide")
    .min(10, "Veuillez entrer un numéro valide")
    .max(10, "Veuillez entrer un numéro valide")
    .required("Ce champ est requis"),
  email: Yup.string()
    .email("Veuillez entrer un e-mail valide")
    .required("Ce champ est requis"),
});

const salutationsList = [
  {
    name: "Madame",
    value: "Madame",
  },
  {
    name: "Monsieur",
    value: "Monsieur",
  },
];

const InputRadio = ({ label, ...props }) => {
  const [field] = useField(props);
  return (
    <>
      <div>
        <input type="radio" {...field} {...props} />
        <label htmlFor={props.id || props.name}>{label}</label>
      </div>
    </>
  );
};


export const ContactShare = (props) => {
  useEffect(() => {
    const tagManagerArgs = {
      dataLayer: {
        event: "form-etape-formulaire",
        userCodePostal: props.codePostal,
        typeOffre: "Solaire",
        userLogement: "Maison",
      },
      dataLayerName: "PageDataLayer",
    };

    TagManager.dataLayer(tagManagerArgs);
  }, []);

  return (
  <div>
    <Formik
      initialValues={{
        firstname: "",
        lastname: "",
        email: "",
        mobilephone: "",
        salutation: "Madame",
      }}
      validationSchema={DisplayingErrorMessagesSchema}
      onSubmit={props.onSubmit}
    >
      {({ errors, touched }) => (
        <Form className="form-inside-input">
          <h1 className="lead-h2 mt-50 is-semibold">
            Affiner votre projet solaire avec{" "}
            <br className="is-hidden-mobile" />
            <span>l’aide d’un conseiller Hellio</span>
          </h1>
          <p className="lead-p mv-20">
            Recevez votre étude détaillée gratuitement.{" "}
            <br className="is-hidden-mobile" />
            Vos données personnelles sont confidentielles
          </p>

          <div className="mt-20 civil-container">
            {salutationsList.map(({ name, value }) => {
              return (
                <InputRadio
                  label={value}
                  id={value}
                  type="radio"
                  name="salutation"
                  value={value.toString()}
                  key={value}
                />
              );
            })}
          </div>
          <div className="row">
            <div className="col is-half">
              <div className="input-container">
                <label>Prénom *</label>
                <Field name="firstname" placeholder="Jean" />
              </div>
              {touched.firstname && errors.firstname && (
                <div className="inputError">{errors.firstname}</div>
              )}
            </div>
            <div className="col is-half">
              <div className="input-container">
                <label>Nom *</label>
                <Field name="lastname" placeholder="Barra" />
              </div>
              {touched.lastname && errors.lastname && (
                <div className="inputError">{errors.lastname}</div>
              )}
            </div>
          </div>

          <div className="row mb-20">
            <div className="col is-half">
              <div className="input-container">
                <label>Mail *</label>
                <Field name="email" placeholder="JeanBarra@mail.com" />
              </div>
              {touched.email && errors.email && <div className="inputError">{errors.email}</div>}
            </div>

            <div className="col is-half">
              <div className="input-container">
                <label>Téléphone *</label>
                <Field
                  name="mobilephone"
                  placeholder="0630003030"
                  maxLength="10"
                />
              </div>
              {touched.mobilephone && errors.mobilephone && (
                <div className="inputError">{errors.mobilephone}</div>
              )}
            </div>
          </div>
          {props.loading && (
            <button className="btn-valider" disabled>
              <div className="lds-ring" style={{ verticalAlign: "middle" }}>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
              </div>
              Envoyer
            </button>
          )}

          {!props.loading && (
            <button type="submit" className="btn-valider">
              Envoyer
            </button>
          )}
          <div class="w-100">
          <p class="mt-10"><small>* En cliquant sur "Envoyer", je reconnais avoir lu et accepté les <a href="https://www.hellio.com/cgu" target="_blank" rel="noopener" class="is-underlined">Conditions générales d'utilisation</a> <br/>et la <a href="https://www.hellio.com/politique-confidentialite" target="_blank" rel="noopener" class="is-underlined">Politique de confidentialité</a>.</small>
          </p>
          </div>

        </Form>
      )}
    </Formik>
  </div>
  )
};

export default ContactShare;
