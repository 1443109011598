import React, { Component } from "react";
import MapWrapper from "../MapWrapper";
import StepBar from "../StepBar";
import Logo from "../Logo";
import TagManager from "react-gtm-module";

class Step2mapParcelle extends Component {
  constructor() {
    super();
    this.state = {
      showErrorMsg: false,
    };
    this.showErrorMsg = this.showErrorMsg.bind(this);
  }

  showErrorMsg = (e) => {
    this.setState({ showErrorMsg: true });
  };

  componentDidMount() {
    const tagManagerArgs = {
      dataLayer: {
        event: "form-etape-toit",
        userCodePostal: this.props.codePostal,
        typeOffre: "Solaire",
      },
      dataLayerName: "PageDataLayer",
    };

    TagManager.dataLayer(tagManagerArgs);
  }

  render() {
    const {
      cadastre,
      address,
      roofNextStep,
      roofLastStep,
      center,
      roofStep,
      handleNextStep,
      handleLastStep,
      handleClickMap,
      zoom,
      step,
      marker,
      calculeRoof,
      handleRoofHeight,
      roofWidth,
      roofHeight,
      handleRoofWidth,
      roofSizeLoading,
      roofSize,
      roofSizeCount,
    } = this.props;

    // handleAddressChange, placeSelected, openPredictions, mapData, handleKeyUp,
    return (
      <div className="outer-container">
        <div className="row">
          <div className="col is-half ph-20">
            <Logo />
            {/* <h2 className="hero-h2">Sinon, déplacez la carte et cliquez sur votre habitation. </h2> */}
            <div>
              {roofStep === 0 && (
                <div>
                  <h1 className="lead-h2 mt-50">
                    Valider <span>l’emplacement du logement à équiper</span>
                  </h1>
                  <p className="mb-30 mt-10 pr-50">
                    Si le marqueur bleu <img src="../images/marker.svg" width="20" alt="marker"/> n’est pas au bon endroit,
                    cliquez sur l’habitation concernée sur la carte
                  </p>
                  <button className="btn-valider" onClick={calculeRoof}>
                    <span className="mr-10">Valider</span>
                    {!roofSizeLoading && (
                    <img src="../../images/check.svg" alt="next" />
                    )}
                    {roofSizeLoading && (
                      <div
                        className="lds-ring"
                        style={{ verticalAlign: "middle" }}
                      >
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                      </div>
                    )}
                  </button>
                </div>
              )}

              {roofStep === 1 && (
                <div>
                  <h1 className="lead-h2">
                    La surface de votre toiture est de{" "}
                    <span className="text-main is-bold">{roofSize} m² ?</span>{" "}
                  </h1>
                  <p className="mv-20">
                    Si cette valeur vous semble inexacte, vous pouvez cliquer
                    sur "Corriger"
                  </p>

                  <div>
                    <button
                      className="btn-normal"
                      style={{ textAlign: "left" }}
                      onClick={handleNextStep}
                    >
                      <img src="../../images/next-round.svg" alt="next" className="icon-hover1" />
                      <img src="../../images/next-round-blue.svg" alt="next" className="icon-hover2" />
                      <span
                        style={{
                          fontSize: "0.8em",
                          fontWeight: "bold",
                          paddingRight: "5px",
                        }}
                      >
                        Cette valeur me semble exacte
                      </span>
                    </button>
                  </div>
                  <div>
                    <button
                      className="btn-normal"
                      style={{ textAlign: "left" }}
                      onClick={roofNextStep}
                    >
                      <img src="../../images/next-round.svg" alt="next" className="icon-hover1" />
                      <img src="../../images/next-round-blue.svg" alt="next" className="icon-hover2" />
                      <span
                        style={{
                          fontSize: "0.8em",
                          fontWeight: "bold",
                          paddingRight: "5px",
                        }}
                      >
                        Je souhaite corriger cette valeur
                      </span>
                    </button>
                  </div>

                  <div>
                    <button
                      className="btn-navigation mt-30 mr-30"
                      onClick={roofLastStep}
                    >
                      <img
                        src="../../images/previous-round-orange.svg"
                        alt="next"
                      />
                      <span>Précédent</span>
                    </button>
                  </div>
                </div>
              )}

              {roofStep === 2 && (
                <div>
                  <h1 className="lead-h2">
                    Quelle est la surface disponible{" "}
                    <span>sur votre toiture ? </span>
                  </h1>
                  <p className="mb-20 mt-10">
                    Merci de renseigner la longueur et la largeur de la toiture
                    de votre maison:
                  </p>
                  <div className="input-container">
                    <div
                      style={{
                        borderLeft: "5px solid #f33f04",
                        paddingLeft: "10px",
                      }}
                    >
                      <div>
                        <label>Longueur</label>
                      </div>
                      <div>
                        <input
                          type="number"
                          value={roofHeight}
                          onChange={handleRoofHeight}
                          placeholder="0"
                          min="1"
                          id="metres"
                        />
                        <span className="metresBadge">m</span>
                      </div>
                    </div>
                  </div>

                  <div className="input-container">
                    <div
                      style={{
                        borderLeft: "5px solid #fba03d",
                        paddingLeft: "10px",
                      }}
                    >
                      <div>
                        <label>Largeur</label>
                      </div>
                      <div>
                        <input
                          type="number"
                          value={roofWidth}
                          onChange={handleRoofWidth}
                          placeholder="0"
                          min="1"
                          id="metres"
                        />
                        <span className="metresBadge">m</span>
                      </div>
                    </div>
                  </div>

                  {roofWidth * roofHeight === 0 && (
                    <div>
                      <button
                        className="btn-valider mt-20"
                        onClick={this.showErrorMsg}
                      >
                        
                        <span className="mr-10">Valider</span>
                        <img src="../../images/check.svg" alt="next" />
                      </button>
                      {this.state.showErrorMsg && (
                        <p style={{ color: "red" }} className="mt-20">
                          rentrer une surface du toit
                        </p>
                      )}
                    </div>
                  )}
                  {roofWidth * roofHeight !== 0 && (
                    <button
                      className="btn-valider mt-20"
                      onClick={roofSizeCount}
                    >
                      <span className="mr-10">Valider</span>
                      
                      
                      <img src="../../images/check.svg" alt="next" />
                    </button>
                  )}
                </div>
              )}

              {roofStep === 2 && (
                <div>
                  <button
                    className="btn-navigation mt-30 mr-30"
                    onClick={roofLastStep}
                  >
                    <img
                      src="../../images/previous-round-orange.svg"
                      alt="next"
                    />
                    <span>Précédent</span>
                  </button>
                </div>
              )}

              {roofStep === 0 && (
                <div>
                  <button
                    className="btn-navigation mt-30 mr-30"
                    onClick={handleLastStep}
                  >
                    <img
                      src="../../images/previous-round-orange.svg"
                      alt="next"
                    />
                    <span>Précédent</span>
                  </button>
                </div>
              )}
            </div>
          </div>

          <div className="col is-half">
            <StepBar step={step} />
            <MapWrapper
              key={center}
              cadastre={cadastre}
              center={center}
              zoom={zoom}
              address={address}
              handleClickMap={handleClickMap}
              marker={marker}
            ></MapWrapper>
          </div>
        </div>
      </div>
    );
  }
}
export default Step2mapParcelle;
