import { React, useState} from "react";
import SuccessMsg from "./SuccessMsg";
import ContactShare from "./ContactShare";
import PackData from "../data/PackData";
export const ContactFinal = (props) => {
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);

  let dataContent = {};
  if(props.api2result["body"] !== undefined) {
     dataContent = {
      jsonExcel: props.api2result["body"]["data"]["JSON Excel"],
      jsonSimulateur: props.api2result.body.data["JSON Simulateur"],
    }
  }
  const onSubmit = (values) => {
    const urlCreatLead = `${process.env.REACT_APP_URL_API}/salesforce/EndSimulation`;
    const bodyData = {
      ...values,
      city: props.city,
      street: props.street,
      postalcode: props.codePostal,
      installation: PackData[props.install]["number"],
      price: PackData[props.install]["price"],
      prime: PackData[props.install]["prime"],
      travaux_prevus__c: props.delais,
      ...dataContent,
      Type_de_contrat__c: props.tarif,
      Mensualite__c: props.factureMontant,
      utm_campaign__c: props.paraList['utm_campaign'],
      utm_content__c: props.paraList['utm_content'],
      utm_medium__c: props.paraList['utm_medium'],
      utm_source__c: props.paraList['utm_source'],
      utm_term__c: props.paraList['utm_term'],
      "p_m": props.roofSize,
      "alpha":props.tiltDegree,
      "azimut":props.exposition,
    };

    console.log(bodyData)

    setLoading(true);

    fetch(urlCreatLead, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `${process.env.REACT_APP_TOKEN_API}`,
      },
      body: JSON.stringify(bodyData),
    })
      .then((result) => result.json())
      .then(
        (result) => {
          console.log(result);
          setSuccess(true);
          setLoading(false);
        },
        (error) => {
          setSuccess(false);
          setError(true);
          setLoading(false);
          console.log(error);
        }
      );
  };


  if (success) return <SuccessMsg codePostal={props.codePostal} handleBackHome={props.handleBackHome} />;
  else if (error) return <p>Erreur</p>;
  else return <ContactShare onSubmit={onSubmit} loading={loading} codePostal={props.codePostal} />;
};

export default ContactFinal;
