import React from "react";
import PacksSlider from "./PacksSlider";
import Logo from "./Logo";
import TagManager from "react-gtm-module";
import CollapseElement from "./CollapseElement";
import Slider from "react-slick";
import PackData from "../data/PackData"
import "slick-carousel/slick/slick.css";

class Offres extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      activeCollapseElementIndex: -1,
      selectYear: 1,
    };

    this.setActiveCollapseElementIndex = this.setActiveCollapseElementIndex.bind(
      this
    );
    this.getNewCollapseElementIndex = this.getNewCollapseElementIndex.bind(
      this
    );
  }

  setActiveCollapseElementIndex(index) {
    this.setState({
      activeCollapseElementIndex: index,
    });
  }
  getNewCollapseElementIndex(currentIndex, newIndex) {
    if (currentIndex > -1 && currentIndex === newIndex) {
      return -1;
    }
    return newIndex;
  }

  componentDidMount() {
    const tagManagerArgs = {
      dataLayer: {
        event: "form-etape-pack",
        userCodePostal: this.props.codePostal,
        typeOffre: "Solaire",
        userLogement: "Maison",
      },
      dataLayerName: "PageDataLayer",
    };

    TagManager.dataLayer(tagManagerArgs);
  }
  handleYear = (e) => {
    this.setState({ selectYear: e.target.value });
  };

  render() {
    var settings = {
      dots: false,
      infinite: false,
      speed: 500,
      slidesToShow: 5,
      slidesToScroll: 5,
      arrows: false,
      responsive: [
        {
          breakpoint: 320,
          settings: { slidesToShow: 3.5, slidesToScroll: 1, infinite: false },
        },
        {
          breakpoint: 768,
          settings: { slidesToShow: 4.5, slidesToScroll: 1, infinite: false },
        },
        {
          breakpoint: 1024,
          settings: { slidesToShow: 5, slidesToScroll: 1, infinite: false },
        },
      ],
    };

    const {
      handleNextStep,
      isLoaded2,
      handleInstall,
      api2result,
      handleBackHome
    } = this.props;

    // If simulator loaded successfuly
    if (api2result["status"] === 200 && isLoaded2) {
      const { activeCollapseElementIndex, selectYear } = this.state;
      const handleYear = this.handleYear;
      const installation = api2result.body.data["JSON Simulateur"];
      const {
        setActiveCollapseElementIndex,
        getNewCollapseElementIndex,
      } = this;
      const resultLength = Object.keys(installation).length;
      const thisYear = new Date().getFullYear();
      // console.log(`installation: ${Object.keys(installation).length}`);
      const OffreCards = [];
      const offreCardsMobile = [];

      Object.keys(installation)
        .sort()
        .map(function (keyName, keyIndex) {
          offreCardsMobile.push(
            <div className="offres-card-mobile only-show-mobile">
              <h1 className="is-bold mv-30">
                <img
                  src="../images/check2.svg"
                  width="25"
                  className="accordion-point mr-5"
                  alt="check"
                />
                Pack <span className="text-main">{PackData[keyName]["packName"]} </span>
                de {PackData[keyName]["number"]} kWc
              </h1>
              <ul className="offres-year-list" style={{ fontSize: "1.2em" }}>
                <Slider {...settings}>
                  {[1, 5, 10, 15, 20].map((item) => (
                    <li
                      key={item}
                      id={selectYear === item ? "selected" : ""}
                      value={item}
                      onClick={handleYear}
                    >
                      {thisYear + item}
                    </li>
                  ))}
                </Slider>
              </ul>
              <div className="accordion-card-mobile">
                <div>
                  <div className="card-section">
                    <h3>
                      Facture d’électricité
                      <span className="text-main"> sans panneaux</span>
                    </h3>

                    <h2 className="is-bold">
                      <del>
                        {
                          installation[keyName][
                            "Facture electricite sans panneaux"
                          ][selectYear]
                        }
                      </del>{" "}
                      <span className="text-main">€</span>
                    </h2>
                  </div>
                  <div className="card-section borders">
                    <h3>
                      Facture d’électricité
                      <span className="text-main"> avec panneaux</span>
                    </h3>

                    <h2 className="is-bold">
                      {
                        installation[keyName][
                          "Facture electricite avec panneaux"
                        ][selectYear]
                      }
                      <span className="text-main"> €</span>
                    </h2>
                  </div>
                  <div className="card-section">
                    <h3>
                      Économies cumulées
                      <span className="text-main"> depuis {thisYear}</span>
                    </h3>

                    <h2 className="is-bold">
                      {installation[keyName]["Economies cumulees"][selectYear]}
                      <span className="text-main"> €</span>
                    </h2>

                    <button
                      className="card-btn-main mv-20"
                      value={PackData[keyName]["number"]}
                      onClick={handleInstall}
                    >
                      J'en profite
                    </button>
                  </div>
                </div>
              </div>
            </div>
          );
          OffreCards.push(
            <CollapseElement
              isActive={activeCollapseElementIndex === keyIndex}
              toggle={() =>
                setActiveCollapseElementIndex(
                  getNewCollapseElementIndex(
                    activeCollapseElementIndex,
                    keyIndex
                  )
                )
              }
              installation={installation}
              keyName={keyName}
              keyIndex={keyIndex}
            >
              <div className="d-flex">
                <div className="accordion-card">
                  <div className="m-10 pv-10">
                    <img src="../images/info.svg" alt="info" className="info" />
                    <h3 className="is-bold">
                      Pack{" "}
                      <span className="text-main">{PackData[keyName]["packName"]}</span>
                    </h3>
                    <p className="mt-10">{PackData[keyName]["description"]}</p>
                  </div>
                  <div className="mh-10 mv-20 align-center">
                    <button
                      className="btn-main"
                      value={PackData[keyName]["number"]}
                      onClick={handleInstall}
                    >
                      J'en profite
                    </button>
                  </div>
                </div>
                <div className="p-10">
                  <ul className="offres-year-list">
                    {[1, 5, 10, 15, 20].map((item) => (
                      <li
                        key={item}
                        id={selectYear === item ? "selected" : ""}
                        value={item}
                        onClick={handleYear}
                      >
                        {thisYear + item}
                      </li>
                    ))}
                  </ul>
                  <div className="offres-year-content d-flex">
                    <div className="card">
                      <div className="w-100">
                        <h3>
                          Facture d’électricité <br />
                          <span className="text-main">sans panneaux</span>
                        </h3>
                      </div>
                      <div className="ph-10">
                        <h2>
                          <del>
                            {
                              installation[keyName][
                                "Facture electricite sans panneaux"
                              ][selectYear]
                            }
                          </del>{" "}
                          <sup>€</sup>
                        </h2>
                      </div>
                    </div>
                    <div className="card">
                      <div className="w-100">
                        <h3>
                          Facture d’électricité <br />
                          <span className="text-main">avec panneaux</span>
                        </h3>
                      </div>
                      <div className="ph-10">
                        <h2>
                          {
                            installation[keyName][
                              "Facture electricite avec panneaux"
                            ][selectYear]
                          }{" "}
                          <sup>€</sup>
                        </h2>
                      </div>
                    </div>

                    <div className="card">
                      <div className="w-100">
                        <h3>
                          Économies cumulées <br />
                          <span className="text-main">depuis {thisYear}</span>
                        </h3>
                      </div>
                      <div className="ph-10">
                        <h2>
                          {
                            installation[keyName]["Economies cumulees"][
                              selectYear
                            ]
                          }{" "}
                          <sup>€</sup>
                        </h2>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </CollapseElement>
          );
        });

      return (
        <div className="container">
          <div className="NavBar_header">
            <Logo />
            <div>
              {/* <div className="callBox" onClick={handleNextStep}>
                <div className="icon">
                  <img src="../images/call.svg" alt="call" />
                </div>
                <p className="is-hidden-mobile">J’aimerais être conseillé</p>
                <div className="callBox_background"></div>
              </div> */}
            </div>
          </div>
          <div className="row">
            <div className="col is-four-fifth">
              <h1 className="lead-h2">
                Hellio vous propose{" "}
                <span className="text-main">
                  {resultLength} pack{resultLength !== 1 ? "s" : ""} au choix
                </span>
              </h1>
              <p className="btn-restart" onClick={handleBackHome}>Recommencer ma simulation</p>
              <div style={{marginTop: '-15px'}}>
                <PacksSlider
                  handleInstall={handleInstall}
                  installation={installation}
                />
                <div
                  className={api2result["status"] !== 200 ? "is-hidden" : ""}
                >
                  <h1 className="offre-h2 mb-30">
                    Vos économies d'aujourd'hui, de demain 
                    <span className="text-main"> et d'après demain !</span>
                  </h1>
                  <p>{offreCardsMobile}</p>

                  <ul className="accordion mt-20 is-hidden-mobile">
                    {OffreCards}
                  </ul>

                  <p className="mv-10"><small> <sup>(1) </sup> Les prix affichés correspondent au montant des travaux, prime à l’autoconsommation déduite. 
                  ils sont indicatifs et seront confirmés ou <br/>modifiés à la suite d'une visite technique réalisée par notre artisan RGE QualiPV.</small></p>
                </div>
              </div>
            </div>
            <div className="col is-one-fifth is-hidden-mobile">
              <div id="background3"></div>
              {/* <img src="../images/offres-hero.png" alt="offres" className="offres-hero" /> */}
            </div>
          </div>
        </div>
      );
    } else {
      // Error Page
      return (
        <div className="container">
          <div className="NavBar_header">
            <Logo />
          </div>

          <div className="row">
            <div className="col is-four-fifth">
              {isLoaded2 && (
                <div>
                  <h1 className="lead-h3">Le calcul n’a pu aboutir, nos ingénieurs planchent sur le sujet. <br/>
                    <span>Contacter l’un de nos conseillers pour une étude solaire personnalisée.</span>
                  </h1>
                  {/* <button className="btn-main mt-30" onClick={handleNextStep}>
                    Continuer
                  </button> */}

                  <div className="callBoxBlue" onClick={handleNextStep}>
                    <div className="icon">
                      <img src="../images/call.svg" alt="call" />
                    </div>
                    <p>J’aimerais être conseillé</p>
                  </div>
                </div>
              )}
              {!isLoaded2 && (
                <h1 className="lead-h2">
                  Nous calculons votre simulation{" "}
                  <div
                    className="lds-ring-2"
                    style={{ verticalAlign: "middle" }}
                  >
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                  </div>
                </h1>
              )}
            </div>
            <div className="col is-one-fifth is-hidden-mobile">
              <img src="../images/offres-hero.png" alt="offres" className="offres-hero" />
            </div>
          </div>
        </div>
      );
    }
  }
}

export default Offres;
