import React from "react";
import {
  MapContainer,
  TileLayer,
  Marker,
  Popup,
  Polygon,
} from "react-leaflet";
import L from "leaflet";
import "leaflet/dist/leaflet.css";
import iconShadow from "leaflet/dist/images/marker-shadow.png";

let DefaultIcon = L.icon({
  iconUrl: '../images/marker.png',
  shadowUrl: iconShadow,
  iconAnchor: [12, 25], // point of the icon which will correspond to marker's location
  popupAnchor: [25, -25], // point from which the popup should open relative to the iconAnchor
});

const fillOptions = { color: "white", fillColor: "rgba(255, 255, 255, 0.3)", weight: 2 };
L.Marker.prototype.options.icon = DefaultIcon;

class MapWrapper extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      center: this.props.center,
      zoom: this.props.zoom,
      address: this.props.address,
      marker: this.props.marker,
      selected: 0,
    };
  }

  onMouseOver = (e) => {
    console.log("onMouseOver");
  };

  render() {
    const { center, zoom } = this.state;
    const { handleClickMap, marker, cadastre } = this.props;
    return (
      <div className="mt-10">
        <MapContainer
          center={center}
          zoom={zoom}
          scrollWheelZoom={true}
          className="mapHeight"
          whenReady={(map) => {
            map.target.on("click", handleClickMap);
          }}
        >
          {cadastre.map((item, index) => (
            <Polygon
              id={index}
              pathOptions={fillOptions}
              positions={item}
            ></Polygon>
          ))}

          {/* <GeoJSON attribution="" data={dataCadastre} /> */}
          <TileLayer
            attribution="&copy;INSEE, IGN"
            maxZoom={19}
            minZoom={18}
            url="https://wxs.ign.fr/pratique/geoportail/wmts?service=WMTS&request=GetTile&version=1.0.0&tilematrixset=PM&tilematrix={z}&tilecol={x}&tilerow={y}&layer=ORTHOIMAGERY.ORTHOPHOTOS&format=image/jpeg&style=normal"
          />

          <Marker position={marker}>
            <Popup>{`Lat ${marker[0]}, Lng ${marker[1]}`}</Popup>
          </Marker>
        </MapContainer>
      </div>
    );
  }
}

export default MapWrapper;
