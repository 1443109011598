const PackData = 
{
    "3.0": {
        "number": "3",
        "packName": "efficace",
        "description": "Donne droit à une prime à l'autoconsommation de 1 290 €",
        "meters":15,
        "panneaux":8,
        "price": 9490,
        "monthly": 78,
        "prime": 1290,
    },
    "4.5": {
        "number": "4.5",
        "packName": "flexible",
        "description": "Donne droit à une prime à l'autoconsommation de 1 440 €",
        "meters": 23,
        "panneaux": 12,
        "price": 12990,
        "monthly": 107,
        "prime": 1440,
    },
    "6.0": {
        "number": "6",
        "packName": "performant",
        "description": "Donne droit à une prime à l'autoconsommation de 1 920 €",
        "meters": 30,
        "panneaux": 16,
        "price": 15490,
        "monthly": 128,
        "prime": 1920,
    },
    "9.0": {
        "number": "9",
        "packName": "premium",
        "description": "Donne droit à une prime à l'autoconsommation de 2 880 €",
        "meters": 45,
        "panneaux":24,
        "price": 19990,
        "monthly": 165,
        "prime":2880
    }
}

export default PackData;