import React from "react";
import Autocomplete from "react-google-autocomplete";

class AddressBar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      errorShow: false,
    };
  }

  validateAlert = () => {
    this.setState({ errorShow: true });
  };

  render() {
    const {
      address,
      handleAddressChange,
      placeSelected,
      handleNextStep,
      handleKeyUp,
      btnText,
      mapData,
    } = this.props;

    return (
      <div>
        <div className="addressBar close">
          <div className="wrapper-left">
            <div className="icon">
              <img src="../images/map.svg" alt="Location"></img>
            </div>
            <div>
              <div className="wrapper-autocomplete home-autocomplete">
                <label>Adresse</label>
                <Autocomplete
                  id="autocomplete"
                  onPlaceSelected={placeSelected}
                  types={["address"]}
                  componentRestrictions={{ country: "fr" }}
                  value={address}
                  onChange={handleAddressChange}
                  onKeyUp={handleKeyUp}
                  placeholder="15 Rue de Plelo, 35000 Rennes"
                  inputAutocompleteValue="off"
                />
              </div>
            </div>
            <div className="is-hidden-mobile">
              {mapData && (
                <button onClick={handleNextStep} className="nextBtn">
                  {btnText}
                </button>
              )}

              {!mapData && (
                <button onClick={this.validateAlert} className="nextBtn">
                  {btnText}
                </button>
              )}
            </div>
          </div>
        </div>

        <div style={{ paddingTop: "70px" }}>
          {mapData && (
            <button
              onClick={handleNextStep}
              className="nextBtn only-show-mobile"
            >
              {btnText}
            </button>
          )}
          {!mapData && (
            <button
              onClick={this.validateAlert}
              className="nextBtn only-show-mobile"
            >
              {btnText}
            </button>
          )}
        </div>

        {this.state.errorShow && (
          <p className="errorMsg">Rentrer une adresse</p>
        )}
      </div>
    );
  }
}

export default AddressBar;
