import React from "react";
import { ReactHeight } from "react-height";
import PackData from "../data/PackData";
class CollapseElement extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      measuredHeight: -1,
      select: "",
    };
    this.onHeightReady = this.onHeightReady.bind(this);
  }
  onHeightReady(newHeight) {
    this.setState({
      measuredHeight: newHeight + 20,
    });
  }
  getCurrentHeight(measuredHeight, isActive) {
    if (measuredHeight > -1 && !isActive) {
      return "0";
    }
    if (measuredHeight > -1 && isActive) {
      return measuredHeight;
    }
    return "auto";
  }
  render() {
    const { measuredHeight } = this.state;
    const { isActive, keyName } = this.props;
    const contentStyle = {
      overflow: "hidden",
      height: this.getCurrentHeight(measuredHeight, isActive),
      paddingTop: "10px",
    };

    return (
      <li className={isActive ? "accordion-title open" : "accordion-title"}>
        <div onClick={this.props.toggle} className="is-bold">
          <img
            src="../images/check2.svg"
            width="25"
            className="accordion-point"
            alt="check"
          />
          Vos économies pour un Pack
          <span className="text-main"> {PackData[keyName]["packName"]} </span>
          de {PackData[keyName]["number"]}
          <span className="text-main"> kWc </span>
          <img
            src={isActive ? "../images/close.svg" : "../images/open.svg"}
            width="20"
            className="accordion-btn"
            alt="close"
          />
        </div>
        <div style={contentStyle} className="transition">
          <ReactHeight onHeightReady={this.onHeightReady}>
            <div className="mt-10">{this.props.children}</div>
          </ReactHeight>
        </div>
      </li>
    );
  }
}

export default CollapseElement;
