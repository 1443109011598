import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import PackData from "../data/PackData";

class PacksSlider extends React.Component {
  constructor(props) {
    super(props);
    this.settings = {
      dots: false,
      infinite: false,
      speed: 500,
      slidesToShow: 4,
      slidesToScroll: 4,
      arrows: false,
      responsive: [
        {
          breakpoint: 320,
          settings: { slidesToShow: 1.5, slidesToScroll: 1.5, infinite: false },
        },
        {
          breakpoint: 768,
          settings: { slidesToShow: 1.5, slidesToScroll: 1.5, infinite: false },
        },
        {
          breakpoint: 1024,
          settings: { slidesToShow: 3, slidesToScroll: 4, infinite: false },
        },
      ],
    };
  }

  shouldComponentUpdate(nextProps) {
    // TODO: add proper implementation that compares objects
    return false;
  }

  render() {
    const { handleInstall, installation } = this.props;
    const SliderCards = [];
    Object.keys(installation)
      .sort()
      .map(function (keyName, keyIndex) {
        return SliderCards.push(
          <div className="top-wrapper">
            <div className="tooltip-wrapper">
              <div className="tooltip">
                <strong>{PackData[keyName]["number"]}</strong> kWc : Cela
                correspond à une surface de {PackData[keyName]["meters"]}m
                <sup>2</sup> sur votre toiture
              </div>
            </div>

            <div className="slider-card" key={keyName}>
              <div className="m-10 p-5">
                <img
                  src="../images/info.svg"
                  alt="info"
                  className="info mt-5"
                />
                <h4 className="is-bold mt-10">
                  Pack{" "}
                  <span className="text-main">
                    {PackData[keyName]["packName"]}
                  </span>{" "}
                  {PackData[keyName]["number"]}{" "}
                  <span className="text-main">kWc</span>
                </h4>
                <p className="mt-5" style={{ minHeight: "60px" }}>
                  <small>{PackData[keyName]["description"]}</small>
                </p>
              </div>

              <div className="w-100 bg-lightmain ph-20 pv-10">
                <h4>{PackData[keyName]["panneaux"]} panneaux</h4>
              </div>

              <div className="m-20">
                <p className="is-bold text-s" style={{ lineHeight: "0.5em" }}>
                  À partir de
                </p>
                <h1 className="is-extrabold mt-10" style={{ lineHeight: "0em" }}>
                  {PackData[keyName]["price"]}{" "}
                  <span className="text-main">
                    <sup>
                      €{" "}
                      <sup>
                        <small
                          style={{
                            color: "#3a3a3a",
                            fontWeight: "normal",
                            fontSize: "0.5em"
                          }}
                        >
                          (1)
                        </small>
                      </sup>
                    </sup>
                  </span>
                </h1>
                <p className="mt-30">ou {PackData[keyName]["monthly"]} € / mois</p>
                <button
                  className="btn-blue"
                  value={keyName}
                  onClick={handleInstall}
                >
                  Affiner mon étude
                </button>
              </div>
            </div>
          </div>
        );
      });
    return <Slider {...this.settings}>{SliderCards}</Slider>;
  }
}

export default PacksSlider;
