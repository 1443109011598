import React from "react";
import Logo from "../Logo";
class LayoutB extends React.Component {
  render() {
    return (
      <div className="outer-container">
      <div className="row">
        <div className="col is-half leftCol">
            <Logo />
          {this.props.children}
        </div>

        <div className="col is-half is-hidden-mobile">
          <ul className='stepBar full'>
            <li><span></span></li>
            <li><span></span></li>
            <li><span></span></li>
            <li><span></span></li>
            <li><span></span></li>
            <li><span></span></li>
          </ul>
        </div>
      </div>
      </div>
    );
  }
}

export default LayoutB;
