import React, { Component } from "react";
import TagManager from "react-gtm-module";
class Step6tarif extends Component {

  componentDidMount() {
    const tagManagerArgs = {
      dataLayer: {
        event: "form-etape-heure",
        userCodePostal: this.props.codePostal,
        typeOffre: "Solaire",
        userLogement: "Maison",
      },
      dataLayerName: 'PageDataLayer',
    };

    TagManager.dataLayer(tagManagerArgs)
  }

  render() {
    const { handleTarif, handleLastStep } = this.props;

    return (
      <div>
        <h2 className="lead-h2">
          Quelle est votre <span>option tarifaire ?</span>
        </h2>
        <p className="lead-p mv-20">
          Cette information nous permet de comprendre vos habitudes de
          consommation afin de calculer les économies réalisées sur votre
          facture.
        </p>

        <div className="input-none">
          <div className="mt-30">
            <input
              type="radio"
              id="basenon"
              name="tarif"
              value="Heures pleines Heures creuses"
              onChange={handleTarif}
            />

            <label htmlFor="basenon">
              <div className="btn-normal">
              <img src="../../images/time-orange.svg" alt="time" className="icon-hover1" />
              <img src="../../images/time-blue.svg" alt="time" className="icon-hover2" />
                <span>Heures pleines et heures creuses</span>
              </div>
            </label>
          </div>
          <div>
            <input
              type="radio"
              id="baseoui"
              name="tarif"
              value="Base"
              onChange={handleTarif}
            />
            <label htmlFor="baseoui">
              <div className="btn-normal">
              <img src="../../images/time-orange.svg" alt="time" className="icon-hover1" />
              <img src="../../images/time-blue.svg" alt="time" className="icon-hover2" />
                <span>Base</span>
              </div>
            </label>
          </div>
        </div>

        <div className="mt-30">
          <button className="btn-navigation mr-30" onClick={handleLastStep}>
            <img src="../../images/previous-round-orange.svg" alt="previous" />
            <span>Précédent</span>
          </button>
          {/* <button className="btn-navigation" onClick={handleNextStep}><span>Suivant</span> <img src="../../images/next-round-orange.svg" alt="next" /></button> */}
        </div>
        {/* {!validate && <p className="lead-error">Ce champ est obligatoire</p>} */}
      </div>
    );
  }
}
export default Step6tarif;
