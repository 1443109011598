import React, { Component } from "react";
import TagManager from "react-gtm-module";

class Step8delais extends Component {
  constructor(props) {
    super(props);
    this.state = {
      errorShow: false,
    };
  }

  componentDidMount() {
    const tagManagerArgs = {
      dataLayer: {
        event: "form-etape-travaux",
        userCodePostal: this.props.codePostal,
        typeOffre: "Solaire",
        userLogement: "Maison",
      },
      dataLayerName: "PageDataLayer",
    };

    TagManager.dataLayer(tagManagerArgs);
  }

  validateAlert = () => {
    this.setState({ errorShow: true });
  };

  render() {
    const {
      handleDelais,
      handleLastStep,
    } = this.props;
    let questions = [
      { id: "A", detail: "Dès que possible" },
      { id: "B", detail: "Dans les mois à venir" },
      { id: "C", detail: "Dans les années à venir" },
    ];

    const questionsList = questions.map((item) => (
      <div key={item.id}>
        <input
          type="radio"
          id={item.id}
          name="delais"
          value={item.detail}
          onChange={handleDelais}
        />

        <label htmlFor={item.id}>
          <div className="btn-normal btn-delais">
              <img src={`../../images/calendar-${item.id}.svg`} alt="delais" className="icon-hover1" />
              <img src={`../../images/calendar-${item.id}-blue.svg`} alt="delais" className="icon-hover2" />


            <span>{item.detail}</span>
          </div>
        </label>
      </div>
    ));

    return (
      <div>
        <h2 className="lead-h2">
          Sous quels délais souhaitez-vous <br className="is-hidden-mobile" />
          <span>réaliser votre projet ? </span>
        </h2>
        <p className="lead-p mv-20">
          Nous souhaitons y répondre au mieux en fonction de votre disponibilité
          et de vos attentes.
        </p>
        <div className="input-none mt-30">{questionsList}</div>

        <button className="btn-navigation mt-20 mr-30" onClick={handleLastStep}>
          <img src="../../images/previous-round-orange.svg" alt="previous" />
          <span>Précédent</span>
        </button>

        {/* {delais &&
                <button value="Valider" onClick={handleNextStep} className="nextBtn mt-30">Valider</button>
                }

                {!delais &&
                    <button value="Valider" onClick={this.validateAlert} className="nextBtn mt-30">Valider</button>            
                }

                {this.state.errorShow &&
                    <p className="errorMsg">champs obligatoire *</p>
                } */}

        {/* <button className="btn-navigation mr-30"  onClick={handleLastStep}><img src="../../images/previous-round-orange.svg" alt="previous"/><span>Précédent</span></button> */}
      </div>
    );
  }
}

export default Step8delais;
