import React, { Component } from "react";
import TagManager from 'react-gtm-module'

class Step4logement extends Component {

  componentDidMount() {
    const tagManagerArgs = {
      dataLayer: {
        event: "form-etape-logement",
        userCodePostal: this.props.codePostal,
        typeOffre: "Solaire", 
      },
      dataLayerName: 'PageDataLayer',
  }

    TagManager.dataLayer(tagManagerArgs)
  }
    
  render() {
    const { handleNextStep, handleLastStep, handleAppartement } = this.props;

    return (
      <div>
        <h2 className="lead-h2">
          Quelle est la nature <span>de votre logement ?</span>
        </h2>
        <p className="lead-p mv-20">
          Cette réponse nous permet de proposer une solution adaptée.
        </p>

        <div>
          <button
            className="btn-normal"
            onClick={handleNextStep}
            style={{ width: "250px", textAlign: "left" }}
          >
              <img src="../../images/next-round.svg" alt="next" className="icon-hover1" />
              <img src="../../images/next-round-blue.svg" alt="next" className="icon-hover2" />
            <span>Une maison</span>
          </button>
        </div>

        <div>
          <button
            className="btn-normal"
            onClick={handleAppartement}
            style={{ width: "250px", textAlign: "left" }}
          >
              <img src="../../images/next-round.svg" alt="next" className="icon-hover1" />
              <img src="../../images/next-round-blue.svg" alt="next" className="icon-hover2" />
            <span>Un appartement</span>
          </button>
        </div>

        <button className="btn-navigation mt-20 mr-30" onClick={handleLastStep}>
          <img src="../../images/previous-round-orange.svg" alt="next" />
          <span>Précédent</span>
        </button>
      </div>
    );
  }
}

export default Step4logement;
