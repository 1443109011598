import { React, useState } from "react";
import SuccessMsg from "./SuccessMsg";
import ContactShare from "./ContactShare";
export const Contact = (props) => {
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const onSubmit = (values) => {
    const urlCreatLead = `${process.env.REACT_APP_URL_API}/salesforce/createContactLead`;
    const bodyData = {
      ...values,
      utm_campaign__c: props.paraList['utm_campaign'],
      utm_content__c: props.paraList['utm_content'],
      utm_medium__c: props.paraList['utm_medium'],
      utm_source__c: props.paraList['utm_source'],
      utm_term__c: props.paraList['utm_term'],
    };
    setLoading(true);

    // console.log(bodyData)
    fetch(urlCreatLead, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `${process.env.REACT_APP_TOKEN_API}`,
      },
      body: JSON.stringify(bodyData),
    })
      .then((result) => result.json())
      .then(
        (result) => {
          console.log(result);
          setSuccess(true);
          setLoading(false);
        },
        (error) => {
          setSuccess(false);
          setError(true);
          setLoading(false);
          console.log(error);
        }
      );
  };
  if (success) return <SuccessMsg handleBackHome={props.handleBackHome} />;
  else if (error) return <p>Erreur</p>;
  else return <ContactShare onSubmit={onSubmit} loading={loading} />;
};

export default Contact;
