import React from "react";
import Logo from "./Logo";
import { Link } from "react-router-dom";

class NavBar extends React.Component {
  render() {
    return (
      <div className="NavBar_header">
        <Logo />

        <div className="w-50">
          <div className="right80">
            <Link to="/contact">
              <div className="callBox">
                <div className="icon">
                  <img src="../images/call.svg" alt="call" />
                </div>
                <p className="is-hidden-mobile">J’aimerais être conseillé</p>
                <div className="callBox_background"></div>
              </div>
            </Link>
          </div>
        </div>
      </div>
    );
  }
}

export default NavBar;
