import React from "react";
import Logo from "../Logo";
import StepBar from "../StepBar";

class LayoutC extends React.Component {
  
  render() {

    return (
      <div className="outer-container">
      <div className="row">
        <div className="col is-half leftCol">
            <Logo grayLogo={true}/>
          {this.props.children}
        </div>

        <div className="col is-half">
              <StepBar step={this.props.step} type={this.props.step === 1? 'long' : this.props.step>=3 ? 'long' : 'short'}/>
        </div>
      </div>
      </div>
    );
  }
}

export default LayoutC;
