import React from "react";

class StepBar extends React.Component {
  render() {
    const stepInStepBar = this.props.step - 2;
    var items = [];
    var bottomItems = [];
    for (var i = 1; i <= 6; i++) {
      bottomItems.push(<li key={"b" + i}></li>);
      if (stepInStepBar > i && stepInStepBar !== 0) {
        items.push(
          <li key={i}>
            <img src="../images/icon-check.png" alt="checked" className="checkedStep" />
            <span>0{i}</span>
          </li>
        );
      } else if (stepInStepBar === i) {
        items.push(
          <li key={i} id="selected">
            <span>0{i}</span>
          </li>
        );
      } else {
        items.push(
          <li key={i}>
            <span>0{i}</span>
          </li>
        );
      }
    }

    return (
      <div className="is-hidden-mobile">
        <ul className={"stepBar"}>{items}</ul>
        {stepInStepBar >= 2 && <ul className="stepBar long">{bottomItems}</ul>}
      </div>
    );
  }
}

export default StepBar;
