import React from "react";
import { NavLink } from "react-router-dom";

class Logo extends React.Component {
  render() {
    return (
      <div className="logo-container">
      <NavLink to="/">
        <img
          src={`../images/logo${this.props.grayLogo?'_gray' : ''}.svg`}
          alt="Hellio"
        />
      </NavLink>

      </div>

    );
  }
}

export default Logo;
